<template>
  <div>
    <el-table
      :border="!inHomePage"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
      tooltip-effect="light"
      :size="inHomePage ? 'mini' : undefined"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="!inHomePage"
      ></el-table-column>

      <el-table-column
        :label="fields.issue.label"
        :prop="fields.issue.name"
        sortable="custom"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'issue')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.resolution.label"
        :prop="fields.resolution.name"
        sortable="custom"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'resolution')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.tag.label"
        :prop="fields.tag.name"
        sortable="custom"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'tag')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.document.label"
        :prop="fields.document.name"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-file
            class="knowledge-base-link"
            :permissions="fields.document.permissions"
            :value="presenter(scope.row, 'document')"
          ></app-list-item-file>
        </template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="180"
        v-if="!inHomePage"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link :to="`/knowledge-base/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <router-link
              :to="`/knowledge-base/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper" v-if="!inHomePage">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { KnowledgeBaseModel } from '@/modules/knowledge-base/knowledge-base-model';
import { mapGetters, mapActions } from 'vuex';
import { KnowledgeBasePermissions } from '@/modules/knowledge-base/knowledge-base-permissions';
import { i18n } from '@/i18n';

const { fields } = KnowledgeBaseModel;

export default {
  name: 'app-knowledge-base-list-table',

  props: ['inHomePage'],

  created() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'knowledgeBase/list/rows',
      count: 'knowledgeBase/list/count',
      loading: 'knowledgeBase/list/loading',
      pagination: 'knowledgeBase/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'knowledgeBase/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new KnowledgeBasePermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new KnowledgeBasePermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'knowledgeBase/list/doChangeSort',
      doChangePaginationCurrentPage:
        'knowledgeBase/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'knowledgeBase/list/doChangePaginationPageSize',
      doMountTable: 'knowledgeBase/list/doMountTable',
      doDestroy: 'knowledgeBase/destroy/doDestroy',
    }),

    presenter(row, fieldissue) {
      return KnowledgeBaseModel.presenter(row, fieldissue);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
